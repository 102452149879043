import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";
import { Dialog, Drawer, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
      maxHeight: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
      height: "",
    },
  },
}));

export default function PickupDropDashboardUI(props: any) {
  const context: any = useContext(DataContext);
  const classes = useStyles();
  const [pickup_location, SetPickupLocation] = useState(true);
  const [drop_location, SetDropLocation] = useState(false);
  const [add_contact_person, SetAddContactPerson] = useState({
    is_open: false,
  });
  const [add_vendor_open, SetAddvendorOpen] = useState(false);

  return (
    <div className="page-main mb-4 bg-body-color p-3 custom-container mb-5">
      {/* <div className="card shadow radius-10 mt-3">
        <div className="d-flex">
          <div className="p-3 pe-0">
            <div className="big-heading">1superapp pickup & drop</div>
            <div className="sub-head-text text-dark mt-2">
              Deliver and receive anything, right away
            </div>
            <div className="sub-text mt-2">
              Delivering from{" "}
              <span className="text-dark fs-13">8AM - 11PM</span>
            </div>
          </div>
          <div className="ms-auto ms-0 pt-2 ">
            <img
              src={
                AppConfig.CDN_Image_url + "27a0f782fdfd6b5a72e0ab0ee78dc4d3.png"
              }
              className="pickup-logo"
            />
          </div>
        </div>
      </div> */}

      <div className="loc-head text-dark py-2">Pickup or Send anything</div>
      <div className="card p-3 shadow radius-10 mt-2">
        {pickup_location ? (
          <div>
            <div className="d-flex w-100">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20"
              />
              <div className="ps-2 address-text text-black-color">
                Pickup{" "}
                <span className="fs-12px text-gray lowercase fw-500 pop-regular px-1">
                  from
                </span>{" "}
                Home
              </div>
              <div className="ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "21d3a9e5cedf31f69753210bf5015802.svg"
                  }
                  className="w-16px"
                />
              </div>
            </div>
            <div className="mt-2 sub-text text-gray let_space_03">
              13, Rangan 2nd Street, Karungalpalayam, Pavalatham Palayam, Erode,
              Tamil Nadu, India, 638003
            </div>
            <div className="pt-2 border-top mt-2">
              <div className="d-flex align-items-center">
                <div className="fs-12 pop-font let_space_03">
                  <span className="text-gray">Contact </span>
                  <span className="text-dark">MySelf | +919874563210 </span>
                </div>
                <div className="ms-auto ps-2 cursor"onClick={()=>{
                  SetAddContactPerson({is_open:true})
                }}>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "21d3a9e5cedf31f69753210bf5015802.svg"
                    }
                    className="w-16px"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="d-flex w-100"
              onClick={() => {
                SetPickupLocation(true);
              }}
            >
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20"
              />
              <div className="ps-2">
                <div className="address-text text-black-color">
                  Pickup Location
                </div>
                {/* <div className="address-sub-text mt-1 let_space_03">
                    Select your pickup location
                  </div> */}
                <p className="text-red fs-11px mt-1 let_space_03 celias-font">
                  Please select a location
                </p>
              </div>
              <div className="ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-12px mb-1"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card p-3 shadow radius-10 mt-3">
        {drop_location ? (
          <div>
            <div className="d-flex w-100">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20"
              />
              <div className="ps-2 address-text text-black-color">
                Drop{" "}
                <span className="fs-12px text-gray lowercase fw-500 pop-regular px-1">
                  to
                </span>{" "}
                Office
              </div>
              <div className="ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "21d3a9e5cedf31f69753210bf5015802.svg"
                  }
                  className="w-16px"
                />
              </div>
            </div>
            <div className="mt-2 sub-text text-gray let_space_03">
              13, Cauvery 2nd Street, Cauvery, Pallipalayam, Namakkal, Tamil
              Nadu, India, 638008
            </div>
            <div className="pt-2 border-top mt-2">
              <div className="d-flex align-items-center">
                <div className="fs-12 pop-font let_space_03">
                  <span className="text-gray">Contact </span>
                  <span className="text-dark">MySelf | +919874563210 </span>
                </div>
                <div className="ms-auto ps-2 cursor" onClick={()=>{
                  SetAddContactPerson({is_open:true})
                }}>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "21d3a9e5cedf31f69753210bf5015802.svg"
                    }
                    className="w-16px"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="d-flex w-100"
              onClick={() => {
                SetDropLocation(true);
              }}
            >
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20"
              />
              <div className="ps-2">
                <div className="address-text text-black-color">
                  Drop Location
                </div>

                {/* <div className="address-sub-text mt-1 let_space_03">
                    Select your drop location
                  </div> */}

                <p className="text-red fs-11px mt-1 let_space_03 celias-font">
                  Please select a location
                </p>
              </div>
              <div className="ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-12px mb-1"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-3 px-2 py-3 card radius shadow">
        <div className="row">
          <div className="col-3 border-right text-center px-1">
            <p className="text-gray extra-small">Pickup in</p>
            <p className="text-black pt-1 v-small fw-bold">
              7 mins
              {/* {sec_to_mins(detail_data.pickup_duration)} mins */}
            </p>
          </div>
          <div className="col-3 border-right text-center px-1">
            <p className="text-gray extra-small">Distance</p>
            <p className="text-black pt-1 v-small fw-bold">
              9.28 km
              {/* {detail_data.total_distance} km */}
            </p>
          </div>

          <div className="col-3 border-right text-center px-1">
            <p className="text-gray extra-small">Delivery in</p>
            <p className="text-black pt-1 v-small fw-bold">
              23 mins
              {/* {sec_to_mins(detail_data.delivery_duration)} mins */}
            </p>
          </div>
          <div className="col-3 text-center px-1">
            <p className="text-gray extra-small">Delivery fee</p>
            <p className="text-black pt-1 v-small fw-bold">
              ₹ 100
              {/* ₹ {detail_data.delivery_charges} */}
            </p>
          </div>
        </div>
      </div>

      <div className="card p-3 shadow radius-10 mt-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <img
              src={
                AppConfig.CDN_Media_url + "85230b78c2d7fe05c738c4057bfc3247.svg"
              }
              className="w-16px"
            />
            <div className="text-gray fs-12px pe-2 ps-1">Pickup in</div>
            <div className="text-dark fs-12px">7mins</div>
          </div>

          <div className="d-flex align-items-center ms-auto">
            <img
              src={
                AppConfig.CDN_Media_url + "d1e55babee767bc446e70ccd924d5e98.svg"
              }
              className="w-12px"
            />
            <div className="text-gray fs-12px pe-2 ps-1">Delivery in</div>
            <div className="text-dark fs-12px">02:45 pm</div>
          </div>
        </div>
      </div>

      <div className="card p-3 shadow radius-10 mt-3">
        <div className="loc-head ">Package Details</div>

        <div className="mt-3 position-relative ">
          <input
            type="text"
            className="outside"
            placeholder="Ex: Documents, files, Books, Groceries, etc"
          />
          <span className="floating-label-outside">Package Content *</span>
        </div>

        {/* <p className="text-red fs-11px py-2 let_space_03 celias-font">
            Please enter the field
          </p> */}

        <div className="mt-3 position-relative">
          <input
            type="number"
            className="outside icon-padding"
            placeholder="Enter package amount"
          />
          <span className="input-icon-absoulate">₹</span>
          <span className="floating-label-outside">Package Value *</span>
          {/* <div className="text-darkgray celias-font">₹</div> */}
        </div>

        

        {/* <label className="v-small text-gray mb-0 celias-font">
          Package Value
          <span className="text-red fs-6 ps-1">*</span>
        </label>
        <div className="input-group center border-bottom">
          <div
            className="input-group-prepend mb-0 pe-2 center"
            // disabled={true}
            // onChange={(e: any) => {
            //   SetMainValue((prevalue: any) => {
            //     prevalue.package_value_currency =
            //       main_value.package_value_currency.value;
            //     return { ...prevalue };
            //   });
            // }}
          >
            <p className="text-darkgray celias-font">
              ₹
            </p>
          </div>
          <input
            type="text"
            className="form-control celias-font only-bottom-border border-radius-none px-0"
            // value={main_value.package_value}
            placeholder="Enter value of the package"
          />
        </div> */}
        {/* <p className="text-red fs-11px py-2 let_space_03 celias-font">
          Please enter the field
        </p> */}
      </div>

      <div className="card p-3 shadow radius-10 mt-3">
        <div className="loc-head ">Delivery Instructions</div>
        <div className="mt-3 position-relative">
          <input
            type="text"
            className="outside"
            placeholder="Ex: More carefull"
          />
          <span className="floating-label-outside">Instructions</span>
          {/* <div className="text-darkgray celias-font">₹</div> */}
        </div>
      </div>

      <div className="">
        <div className="card p-3 shadow radius-10 mt-3">
          <div className="loc-head ">Points to remember</div>

          <div className="d-flex align-items-center mt-3">
            <img
              src={
                AppConfig.CDN_Media_url + "c0e1617d6960218d6c38d2fbd851d7ea.svg"
              }
              className="w-16px"
            />
            <div className="ps-2 text-gray fs-11px let_space_03 sub-text">
              Avoid sending breakable and expensive items
            </div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <img
              src={
                AppConfig.CDN_Media_url + "e95bbe66971cbc3df306194fd84022fa.svg"
              }
              className="w-16px"
            />
            <div className="ps-2 text-gray fs-11px let_space_03 sub-text">
              Alcohol or illegal items are not permitted
            </div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <img
              src={
                AppConfig.CDN_Media_url + "ccabcb7554c3779e450ab77f306e0da5.svg"
              }
              className="w-14px"
            />
            <div className="ps-2 text-gray fs-11px let_space_03 sub-text">
              The item should be sized to fit in a backpack
            </div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <img
              src={
                AppConfig.CDN_Media_url + "8a65421ed67040fa681384a929966fa1.svg"
              }
              className="w-14px"
            />
            <div className="ps-2 text-gray fs-11px let_space_03 sub-text">
              Order before 7PM to avoid delays in delivery
            </div>
          </div>
        </div>
      </div>

      <div className="pt-3 pb-2">
        <div className="fs-9px pop-regular text-center text-gray let_space_03">
          By continuing, you agree to our{" "}
          <span className="text-primary">T&C</span> and there are no{" "}
          <span className="text-primary">restricted items</span> in the package.
          SuperStore shall not be liable for any theft/loss/misplacement/damages
          to the items in any manner whatsover.
        </div>
      </div>

      <button className="primary-button mt-3">Confirm order</button>


      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_contact_person.is_open}
        onClose={() => {
          SetAddContactPerson({ is_open: false });
        }}
      >
        <div className="p-3 shadow ">
          <div className="center d-md-none d-sm-block pb-1">
            <hr className="hr-drawer" />
          </div>
          <div className="text-center">
            <div className="loc-head fs-16px">
              Are you ordering for someone else?
            </div>
            <div className="od-id fw-500 text-black">
              Add contact person phone number for this task to directly
              coordinate with them
            </div>
          </div>

          {/* <div className=" d-flex align-items-center">
            <img
              src={require("../../../assets/svg/location-call.svg")}
              className="w-20px"
            />
            <div className="address-sub-text fs-10px ">
              We will directly coordinate with the contact person using this
              phone number
            </div>
          </div> */}

          <div className="text-center ">
            <div className="position-relative mt-3">
              <input type="text" className="outside" required />
              <span className="floating-label-outside">
                Add contact person name
              </span>
            </div>

            <div className="position-relative mt-3">
              <input type="number" className="outside" required />
              <span className="floating-label-outside">Add phone number</span>
            </div>

            <div
              className="mt-4 light-gray-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              Yes, add contact person details
            </div>
            <div
              className="mt-3 light-primary-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              No, it's for me
            </div>
          </div>
        </div>
      </Dialog>
     
    </div>
  );
}
